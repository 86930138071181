import api from "../services/base-services";
import {
  API_LOGIN,
  API_GET_ALL_POSTS,
  API_REGISTER,
  API_GET_POST_BY_ID,
  API_GET_TOKEN,
  API_GET_POST_BY_CATEGORY,
  API_GET_POST_SEARCH,
  API_GET_FACTCHECK_ALL,
  API_GET_ALL_VIDEO,
  API_GET_POST_BY_TAG,
  API_GET_LIVE_NEWS,
  API_GET_LIVE_POST,
  API_GET_LIVE_POST_BY_ID,
  API_GET_TRENDING_POSTS,
  API_GET_ADS,
  API_POST_COUNT,
  API_GET_INFO,
  API_ADD_VIEW_COUNT,
  API_GET_VIDEO_BY_CATEGORY,
  API_FORGOT_PASSWORD,
  API_SEND_OTP,
  API_RESET_PASSWORD,
} from "../constants/api";

export default class AppServices {
  static UserLogin(data) {
    return api.post(API_LOGIN, data);
  }

  static UserRegister(data) {
    return api.post(API_REGISTER, data);
  }

  static getAllPosts(from, to) {
    return api.get(API_GET_ALL_POSTS, { params: { from: from, to: to } });
  }

  static getPostById(slug) {
    return api.get(API_GET_POST_BY_ID, { params: { slug: slug } });
  }

  static getPostByCategory(data, from, to) {
    return api.get(API_GET_POST_BY_CATEGORY, {
      params: { value: data, from: from, to: to },
    });
  }

  static getPost(data) {
    return api.get(API_GET_POST_SEARCH, {
      params: { keyword: data },
    });
  }

  static getPostBySearch(data) {
    return api.get(API_GET_POST_SEARCH, {
      params: { keyword: data },
    });
  }

  static getToken(token) {
    return api.get(API_GET_TOKEN, token);
  }

  static getFactCheckAll(from, to, language) {
    return api.get(API_GET_FACTCHECK_ALL, {
      params: { from: from, to: to, language: language },
    });
  }

  static getAllVideo(from, to) {
    return api.get(API_GET_ALL_VIDEO, { params: { from: from, to: to } });
  }

  static getPostByTag(data) {
    return api.get(API_GET_POST_BY_TAG, {
      params: { tag: data },
    });
  }

  static getLiveNews() {
    return api.get(API_GET_LIVE_NEWS);
  }

  static getLivePost(from, to, language) {
    return api.get(API_GET_LIVE_POST, {
      params: {
        from: from,
        to: to,
        language: language,
      },
    });
  }

  static getLivePostById(slug) {
    return api.get(API_GET_LIVE_POST_BY_ID, {
      params: { slug: slug },
    });
  }

  static getTrendingPosts(data) {
    return api.get(API_GET_TRENDING_POSTS, {
      params: { value: data },
    });
  }

  static getAllAds() {
    return api.get(API_GET_ADS);
  }

  static addCount(data) {
    return api.post(API_POST_COUNT, data);
  }

  static getAllInfo() {
    return api.get(API_GET_INFO);
  }

  static addViewCount(data) {
    return api.post(API_ADD_VIEW_COUNT, data);
  }

  static getVideoByCategory(data, from, to) {
    return api.get(API_GET_VIDEO_BY_CATEGORY, {
      params: { value: data, from: from, to: to },
    });
  }

  static forgotPassword(data) {
    return api.post(API_FORGOT_PASSWORD, data);
  }

  static sendOTP(data) {
    return api.post(API_SEND_OTP, data);
  }

  static resetPassword(data) {
    return api.post(API_RESET_PASSWORD, data);
  }
}
