import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { getAllPosts } from "../../../store/actions/posts-actions";
import NoImg from "../../../img/no-img.jpg";
import {
  API_STORAGE_BASE_URL,
  WEB_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

import moment from "moment";

class Carousel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      success: false,
    };
  }
  componentDidMount() {
    if (this.props.postState.success) {
      this.setState({
        ...this.state,
        data: this.props.postState.data,
        success: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.postState !== prevProps.postState) {
      if (this.props.postState.success) {
        this.setState({
          ...this.state,
          data: this.props.postState.data,
          success: true,
        });
      }
    }
  }

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  handleRedirect = (name) => {
    window.top.location.href = WEB_BASE_URL + `/article.php?slug=` + name;
    // this.props.history.push(`/article/` + name);
  };

  render() {
    if (this.state.success) {
      return (
        <Fragment>
          <div
            id="carouselId"
            className="carousel slide mb-5"
            data-ride="carousel"
          >
            {/* <ol className="carousel-indicators">
                  <li
                    data-target="#carouselId"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#carouselId" data-slide-to="1"></li>
                  <li data-target="#carouselId" data-slide-to="2"></li>
                </ol> */}
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="row">
                  {this.state.success &&
                    this.state.data
                      .map((item, index) => {
                        var thumbnail = "";
                        if (item.Thumbnail) {
                          var getImagePath = item.Thumbnail.split("/");
                          var thumb = getImagePath[3].split(".");
                          thumbnail = thumb[0];
                        }
                        return (
                          <div className="col-4" key={index}>
                            <div
                              className="card latest-news-card"
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  src={
                                    thumbnail.length !== 0 && thumbnail
                                      ? API_STORAGE_BASE_THUMB_URL +
                                        thumbnail +
                                        ".jpeg"
                                      : item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  onError={(e) => {
                                    this.addImgErrorDefaultSrc(
                                      e,
                                      item.Thumbnail
                                    );
                                  }}
                                  alt={`${item.Title.substring(0, 60)}...`}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm business-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon
                                    className="mr-2"
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                  <p>
                                    {moment(item.DateCreated)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <h1 className="ln-text-01">{item.Title}</h1>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(0, 3)}
                </div>
              </div>
              {this.state.success && this.state.data.length > 3 ? (
                <div className="carousel-item">
                  <div className="row">
                    {this.state.data
                      .map((item, index) => {
                        var thumbnail = "";
                        if (item.Thumbnail) {
                          var getImagePath = item.Thumbnail.split("/");
                          var thumb = getImagePath[3].split(".");
                          thumbnail = thumb[0];
                        }
                        return (
                          <div className="col-4" key={index}>
                            <div
                              className="card latest-news-card"
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  src={
                                    thumbnail.length !== 0 && thumbnail
                                      ? API_STORAGE_BASE_THUMB_URL +
                                        thumbnail +
                                        ".jpeg"
                                      : item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  onError={(e) => {
                                    this.addImgErrorDefaultSrc(
                                      e,
                                      item.Thumbnail
                                    );
                                  }}
                                  alt={`${item.Title.substring(0, 60)}...`}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm business-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon
                                    className="mr-2"
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                  <p>
                                    {moment(item.DateCreated)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <p className="ln-text-01">{item.Title}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(3, 6)}
                  </div>
                </div>
              ) : null}

              {this.state.success && this.state.data.length > 6 ? (
                <div className="carousel-item">
                  <div className="row">
                    {this.state.data
                      .map((item, index) => {
                        var thumbnail = "";
                        if (item.Thumbnail) {
                          var getImagePath = item.Thumbnail.split("/");
                          var thumb = getImagePath[3].split(".");
                          thumbnail = thumb[0];
                        }
                        return (
                          <div className="col-4" key={index}>
                            <div
                              className="card latest-news-card"
                              onClick={(e) => this.handleRedirect(item.Slug)}
                            >
                              <div className="latest-news-img-container">
                                <img
                                  className="latest-news-img"
                                  src={
                                    thumbnail.length !== 0 && thumbnail
                                      ? API_STORAGE_BASE_THUMB_URL +
                                        thumbnail +
                                        ".jpeg"
                                      : item.Thumbnail.length !== 0 &&
                                        item.Thumbnail
                                      ? API_STORAGE_BASE_URL + item.Thumbnail
                                      : NoImg
                                  }
                                  onError={(e) => {
                                    this.addImgErrorDefaultSrc(
                                      e,
                                      item.Thumbnail
                                    );
                                  }}
                                  alt={`${item.Title.substring(0, 60)}...`}
                                />
                              </div>
                              <div className="card-body p-3">
                                <button
                                  type="button"
                                  className="btn btn-sm business-news-button"
                                >
                                  {item.Category}
                                </button>
                                <div className="row pl-3 pr-3 latest-news-subtext">
                                  <QueryBuilderIcon
                                    className="mr-2"
                                    style={{
                                      width: "16px",
                                      height: "16px",
                                    }}
                                  />
                                  <p>
                                    {moment(item.DateCreated)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </p>
                                </div>
                                <p className="ln-text-01">{item.Title}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      .slice(6, 9)}
                  </div>
                </div>
              ) : null}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselId"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselId"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div
            id="carouselId"
            className="carousel slide mb-5"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active">
                <div className="row">
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="row">
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="card latest-news-card">
                      <div className="latest-news-img-container">
                        <Skeleton variant="rect" height={189} />
                      </div>
                      <div className="card-body p-3">
                        <div className="row pl-3 pr-3 mb-2">
                          <Skeleton variant="rect" width={110} height={18} />
                        </div>
                        <Skeleton variant="rect" height={40} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselId"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselId"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.posts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllPosts: (from, to) => dispatch(getAllPosts(from, to)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);
