import React, { Fragment } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import logo from "./Image/404-error.png";
class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <div
          className="d-flex justify-content-center "
          style={{ marginTop: "100px" }}
        >
          <div className="row d-flex flex-row d-flex justify-content-center">
            <div className="col-md-4 d-flex align-items-center">
              <div className="row d-flex flex-row d-flex justify-content-center">
                <div className="col-md-12 d-flex justify-content-center">
                  <h1 className="text-center d-flex align-self-center">
                    Page Not Found.
                  </h1>
                </div>
                <div className="col-md-12 d-flex justify-content-center mt-3">
                  <button
                    className=" btn btn-danger"
                    onClick={() => {
                      window.top.location.href = `/`;
                      // this.props.history.push(`/`);
                    }}
                  >
                    Go Back To Home Page
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-8 d-flex justify-content-center">
              <img
                src={logo}
                className="page-not-found-img "
                alt="Page-Not-Found"
              />
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center">
          <p className="main-access-denied-label-text text-center  mt-5">
            Access Denied!
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <p className="access-denied-label-text text-center  mt-5">
            Sorry about that, but you do not have permission to access the
            requested page!
          </p>
        </div> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound);
