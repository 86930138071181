import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import logo from "../../img/logo.png";
import facebook from "../../img/facebook.svg";
import instagram from "../../img/instagram.svg";
import twitter from "../../img/twitter.svg";
import youtube from "../../img/youtube.svg";
import "./index.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latest: [],
      search: [],
      keyword: "",
      showSearch: false,
      isLoading: false,
      success: false,
      activeList: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      active: 10,
      errorMsg: "",
      isError: false,
    };
  }
  logout = () => {
    window.localStorage.setItem("login", false);
    // this.props.history.push("/login");
    window.top.location.href = `/login`;
  };

  closeNav = (url) => {
    if (url === "login") {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("mySidebar-bd").style.width = "0";
      document.getElementById("mySidebar-bd").style.visibility = "hidden";
      document.getElementById("mySidebar").style.visibility = "hidden";
      document.getElementsByClassName(
        "side-navbar-info-container"
      )[0].style.visibility = "hidden";
      window.top.location.href = `/login`;
      // this.props.history.push("/login");
    } else if (url === "register") {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("mySidebar-bd").style.width = "0";
      document.getElementById("mySidebar-bd").style.visibility = "hidden";
      document.getElementById("mySidebar").style.visibility = "hidden";
      document.getElementsByClassName(
        "side-navbar-info-container"
      )[0].style.visibility = "hidden";
      window.top.location.href = `/register`;
      // this.props.history.push("/register");
    } else {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("mySidebar-bd").style.width = "0";
      document.getElementById("mySidebar-bd").style.visibility = "hidden";
      document.getElementById("mySidebar").style.visibility = "hidden";
      document.getElementsByClassName(
        "side-navbar-info-container"
      )[0].style.visibility = "hidden";
    }
  };
  openNav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar-bd").style.width = "100%";
    document.getElementById("mySidebar-bd").style.visibility = "initial";
    document.getElementById("mySidebar").style.visibility = "initial";
    document.getElementsByClassName(
      "side-navbar-info-container"
    )[0].style.visibility = "initial";
  };

  handleCategory = (name, id, prev, next) => {
    this.handleCategorySwitch(prev, next);
    document.getElementById("collapseExample").classList.remove("show");
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("mySidebar-bd").style.width = "0";
    document.getElementById("mySidebar-bd").style.visibility = "hidden";
    document.getElementById("mySidebar").style.visibility = "hidden";
    document.getElementsByClassName(
      "side-navbar-info-container"
    )[0].style.visibility = "hidden";
    window.top.location.href = `/category/` + name;
    // this.props.history.push(`/category/` + name);
  };

  handleCategorySwitch = (prev, next) => {
    this.setState({
      ...this.state,
      activeList: { ...this.state.activeList, [prev]: false, [next]: true },
      active: next,
    });
  };
  render() {
    var isLoggedIn = window.localStorage.getItem("login");
    return (
      <Fragment>
        <div className="header-01">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div
                    className="row d-flex jalign-items-center "
                    style={{ alignContent: "center" }}
                  >
                    <img
                      className="header-logo"
                      src={logo}
                      alt="logo"
                      onClick={(e) => (window.location.href = "/")}
                    />
                  </div>
                </div>
                <div className="col-6 d-flex justify-content-end ">
                  <div className="row mb-2">
                    <i
                      className="fa fa-bars fa-1x align-items-center"
                      id="hamburger-navbar-icon"
                      aria-hidden="true"
                      style={{
                        color: " white",
                        cursor: "pointer",
                        marginTop: "0.053vw",
                      }}
                      onClick={(e) => this.openNav()}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="side-navbar-wrapper" id="mySidebar">
          <div className="back-drop" id="mySidebar-bd">
            <div className="row">
              <div
                className="col-12 col-sm-12 side-navbar-info-container"
                style={{ backgroundColor: "black" }}
              >
                <div className="row">
                  <div className="col-6 col-sm-9 col-md-8 col-lg-7 col-xl-6">
                    <div className="row">
                      <a href="/" style={{ textDecoration: "none" }}>
                        <img
                          className="header-logo-2 my-auto"
                          src={logo}
                          alt="WorldReady"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-3 col-md-4 col-lg-5 col-xl-6">
                    <div className="row d-flex justify-content-end h-100 align-items-center">
                      <div className="col-1 d-flex justify-content-end my-auto p-0 ml-2 ml-sm-0">
                        <i
                          className="fa fa-times fa-2x"
                          aria-hidden="true"
                          style={{
                            color: "white",
                            cursor: "pointer",
                            marginRight: "29px",
                          }}
                          onClick={(e) => this.closeNav("logout")}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-sm-12 col-md-12 text-start">
                    <div className="side-navbar-links">
                      <div className="row">
                        <div className="col-12 mt-2">
                          <a className="ml-3" href="/">
                            Home
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a
                            className="ml-3"
                            href="https://www.helakuru.lk/esana"
                          >
                            එසැණ
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://ta.citizen.lk/"}>
                            தமிழ்
                          </a>
                        </div>
                        <div className="col-12 mt-2">
                          <a className="ml-3" href={"https://si.citizen.lk/"}>
                            Sinhala
                          </a>
                        </div>
                        {isLoggedIn === "true" ? (
                          <div className="col-12 mt-2">
                            <a
                              className="ml-3"
                              href={"https://www.citizen.lk"}
                              onClick={(e) => this.logout()}
                            >
                              Log Out
                            </a>
                          </div>
                        ) : (
                          <Fragment>
                            <div className="col-12 mt-2">
                              <div
                                className="ml-3 sidebar-collapse-text"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                onClick={(e) => this.closeNav("login")}
                              >
                                Login
                              </div>
                            </div>
                            <div className="col-12 mt-2">
                              <div
                                className="ml-3 sidebar-collapse-text"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                onClick={(e) => this.closeNav("register")}
                              >
                                Register
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 mt-2">
                    <ul id="category-tabs">
                      <li>
                        <a
                          href="#"
                          className="main-category"
                          data-toggle="collapse"
                          data-target="#collapseExample1"
                          aria-expanded="false"
                          aria-controls="collapseExample1"
                        >
                          Menu
                          <i
                            className="fa fa-minus"
                            data-toggle="collapse"
                            data-target="#collapseExample1"
                            aria-expanded="false"
                            aria-controls="collapseExample1"
                          ></i>
                        </a>
                        <div className="collapse" id="collapseExample1">
                          <ul className="sub-category-tabs">
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "local",

                                    this.state.active,
                                    5
                                  )
                                }
                              >
                                Local
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "foreign",

                                    this.state.active,
                                    1
                                  )
                                }
                              >
                                Foreign
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "sports",

                                    this.state.active,
                                    2
                                  )
                                }
                              >
                                Sports
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "politics",

                                    this.state.active,
                                    3
                                  )
                                }
                              >
                                Politics
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "business",

                                    this.state.active,
                                    4
                                  )
                                }
                              >
                                Business
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "opinion",

                                    this.state.active,
                                    4
                                  )
                                }
                              >
                                Opinion
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "tech",

                                    this.state.active,
                                    4
                                  )
                                }
                              >
                                Tech
                              </div>
                            </li>
                            <li>
                              <div
                                onClick={(e) =>
                                  this.handleCategory(
                                    "climate",

                                    this.state.active,
                                    4
                                  )
                                }
                              >
                                Climate Change
                              </div>
                            </li>
                            {/* <li>
                            <a
                              onClick={(e) =>
                                this.handleCategory(
                                  "මෙන්න ඇත්ත",
                                  5,
                                  this.state.active,
                                  5
                                )
                              }
                            >
                              {" "}
                              මෙන්න ඇත්ත
                            </a>
                          </li> */}
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row ml-1 navbar-bottom-row d-flex align-items-end">
                  <div
                    className="col-md-12 d-flex flex-row my-0 p-0"
                    style={{ height: "fit-content" }}
                  >
                    <div className="navbar-social-col d-flex justify-content-center ">
                      <a
                        className="my-auto"
                        href="https://www.facebook.com/citizenlk/"
                      >
                        <img
                          // className="icon-circle-01"
                          src={facebook}
                          alt="facebook"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://www.instagram.com/citizen.lk/"
                      >
                        <img
                          src={instagram}
                          alt="instagram"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://twitter.com/citizen_newslk"
                      >
                        <img
                          // className="icon-circle-02"
                          src={twitter}
                          alt="twitter"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                    <div className="navbar-social-col d-flex justify-content-center ml-2">
                      <a
                        className="my-auto"
                        href="https://www.youtube.com/channel/UCNudBJTq-9sEGCgtL2F0AZA"
                      >
                        <img
                          // className="icon-circle-02"
                          src={youtube}
                          alt="youtube"
                          style={{ verticalAlign: "revert" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 ml-2 my-3 p-0">
                    <p className="navbar-copyright-text mb-0">
                      © Citizen.lk All rights reserved. Digital Content Private
                      Limited, No 96/8, Kottanuwatta Road, Nugegoda, Sri Lanka.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      // <nav class="navbar navbar-expand-lg navbar-light bg-light">
      //   <div className="col-6">
      //     <img
      //       className="header-logo"
      //       src={logo}
      //       alt="logo"
      //       onClick={(e) => (window.location.href = "/")}
      //     />
      //   </div>
      //   <div className="col-6 d-flex justify-content-end">
      //     <a
      //       className="text-dark my-auto mx-3"
      //       data-toggle="collapse"
      //       href="#collapseExample"
      //       role="button"
      //       aria-expanded="false"
      //       aria-controls="collapseExample"
      //     >
      //       <i className="fa fa-search fa-lg" aria-hidden="true"></i>
      //     </a>
      //     <button
      //       class="navbar-toggler"
      //       type="button"
      //       data-toggle="collapse"
      //       data-target="#navbarSupportedContent"
      //       aria-controls="navbarSupportedContent"
      //       aria-expanded="false"
      //       aria-label="Toggle navigation"
      //     >
      //       <span class="navbar-toggler-icon"></span>
      //     </button>
      //     {/* <i className="fa fa-align-right my-auto" aria-hidden="true"></i> */}
      //   </div>

      //   <div class="collapse navbar-collapse" id="navbarSupportedContent">
      //     <ul class="navbar-nav mr-auto">
      //       <li class="nav-item active">
      //         <a
      //           class="nav-link"
      //           data-toggle="collapse"
      //           data-target="#navbarSupportedContent"
      //           aria-controls="navbarSupportedContent"
      //           href="/en/"
      //         >
      //           Home
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href="#">
      //           Academy
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href="https://www.helakuru.lk/esana">
      //           එසැණ
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href="https://citizen.lk/fc/">
      //           Fact Check
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href="https://yournews.lk/">
      //           Your News
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href={"https://citizen.lk/si/"}>
      //           Sinhala
      //         </a>
      //       </li>
      //       <li class="nav-item">
      //         <a class="nav-link" href={"https://citizen.lk/en"}>
      //           Tamil
      //         </a>
      //       </li>
      //       {isLoggedIn === "true" ? (
      //         <li class="nav-item">
      //           <p
      //             class="nav-link"
      //             data-toggle="collapse"
      //             data-target="#navbarSupportedContent"
      //             aria-controls="navbarSupportedContent"
      //             href={"https://www.en.breakingnow.lk"}
      //             onClick={(e) => this.logout()}
      //           >
      //             Log Out
      //           </p>
      //         </li>
      //       ) : (
      //         <Fragment>
      //           <li class="nav-item">
      //             <p
      //               class="nav-link mb-1"
      //               data-toggle="collapse"
      //               data-target="#navbarSupportedContent"
      //               aria-controls="navbarSupportedContent"
      //               onClick={(e) => this.props.history.push("/login")}
      //             >
      //               Login
      //             </p>
      //           </li>
      //           <li class="nav-item">
      //             <p
      //               class="nav-link"
      //               data-toggle="collapse"
      //               data-target="#navbarSupportedContent"
      //               aria-controls="navbarSupportedContent"
      //               onClick={(e) => this.props.history.push("/register")}
      //             >
      //               Register
      //             </p>
      //           </li>
      //         </Fragment>
      //       )}
      //     </ul>
      //   </div>
      // </nav>
    );
  }
}

export default withRouter(Sidebar);
