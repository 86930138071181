import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import appStateReducer from "./reducers/app-state-reducer";
import postStateReducer from "./reducers/posts-state-reducer";
import videoStateReducer from "./reducers/video-state-reducer";
import liveNewsStateReducer from "./reducers/live-news-reducer";
export const store = createStore(
  combineReducers({
    appStore: appStateReducer,
    postStore: postStateReducer,
    videoStore: videoStateReducer,
    livePostStore: liveNewsStateReducer,
  }),
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : (f) => f
  )
);
