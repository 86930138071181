import React, { useState } from "react";

import Navbar from "./Navbar/navbar";
import Footer from "./Footer/footer";
import Routes from "./Routes/index";
import AuthRoutes from "./Routes/auth";
import "./App.css";
import { Fragment } from "react";
import Sidebar from "./Sidebar/index";
// let auth = window.localStorage.getItem("login")
//   ? window.localStorage.getItem("login")
//   : false;

let auth = true;
function App() {
  const [status, setStatus] = useState(auth);
  return (
    <Fragment>
      {status ? (
        <Fragment>
          <header>
            <div className="d-block d-sm-none">
              <Sidebar />
            </div>
            <Navbar />
          </header>
          <div className="main-container">
            <main>
              <Routes />
            </main>
          </div>
          <footer>
            <Footer />
          </footer>
        </Fragment>
      ) : (
        <AuthRoutes status={status} setStatus={setStatus} />
      )}
    </Fragment>
  );
}

export default App;
