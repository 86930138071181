import {
  ACTION_GET_LIVE_POST,
  ACTION_GET_LIVE_POST_BY_ID,
} from "../../constants/action";

const initialState = {
  liveNewsList: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  liveNews: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};
export default function liveNewsStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_LIVE_POST: {
      _state = {
        ...state,
        liveNewsList: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_LIVE_POST_BY_ID: {
      _state = {
        ...state,
        liveNews: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
