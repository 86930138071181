import React, { PureComponent, Fragment } from "react";
import "./index.css";

import NewsBackground from "../../img/news-cuate.svg";
// import GooglePlay from "../../img/GooglePlay.svg";
// import AppleStore from "../../img/AppleStore.svg";
// import calender from "../../img/calender.svg";
// import clock from "../../img/clock.svg";
import { loginUser } from "../../store/actions/app-state-action";
import { connect } from "react-redux";
class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      validMsg: "",
      isValid: false,
      spinner: false,
      errorMsg: "",
      isError: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.loginState !== prevProps.loginState) {
      if (
        this.props.loginState.success &&
        !this.props.loginState.isLoading &&
        this.props.loginState.status === 1
      ) {
        window.localStorage.setItem("login", true);
        window.localStorage.setItem(
          "accessToken",
          this.props.loginState.data.accessToken
        );
        window.localStorage.setItem(
          "refreshToken",
          this.props.loginState.data.refreshToken
        );
        this.showSuccess();
      } else if (
        this.props.loginState.success &&
        this.props.loginState.status === -1
      ) {
        this.setState({ ...this.state, spinner: false });
        this.showError(this.props.loginState.message);
      } else if (
        this.props.loginState.isError &&
        !this.props.loginState.isLoading
      ) {
        this.setState({ ...this.state, spinner: false });
        this.showError("Incorrect email or password!");
      }
    }
  }

  handleChange = (e) => {
    const { id, value } = e.target;
    this.setState({ ...this.state, [id]: value });
  };
  handleClick = () => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (this.state.password.length === 0 && this.state.email.length === 0) {
      this.showError("Required fields are empty!");
    } else if (this.state.password.length === 0) {
      this.showError("Password cannot be empty!");
    } else if (this.state.email.length === 0) {
      this.showError("Email cannot be empty!");
    } else if (!pattern.test(this.state.email)) {
      this.showError("Invalid email format!");
    } else {
      this.setState({ ...this.state, spinner: true });
      this.props.loginUser({
        email: this.state.email,
        password: this.state.password,
      });
    }
  };

  handleRegister = () => {
    window.top.location.href = `/register`;
    // this.props.history.push("/register");
  };

  showSuccess = () => {
    this.setState(
      {
        ...this.state,
        validMsg: "Successfully logged in!",
        isValid: true,
        spinner: false,
      },
      () => {
        window.setTimeout(() => {
          this.setState({
            validMsg: "",
            isValid: false,
          });
          window.top.location.href = `/`;
          // this.props.history.push("/");
        }, 2000);
      }
    );
  };

  showError = (msg) => {
    this.setState(
      {
        ...this.state,
        errorMsg: msg,
        isError: true,
        spinner: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            errorMsg: "",
            isError: false,
          });
        }, 3500);
      }
    );
  };

  render() {
    return (
      <Fragment>
        <div className="login-wrapper">
          <div className="row mx-0">
            <div className="col-md-6 d-flex flex-row align-items-center justify-content-center mx-0">
              <img
                className="citizen-logo"
                src={NewsBackground}
                alt="Citizen"
              />
            </div>
            <div className="col-md-6 d-flex flex-row align-items-center mx-0">
              <div className="card">
                <div className="card-body">
                  <p className="login-text-01">Sign In</p>
                  <p className="login-text-02">
                    Please enter your email and password below to login.
                  </p>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          aria-describedby="helpId"
                          placeholder="Email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          aria-describedby="helpId"
                          placeholder="Password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mt-3">
                      {this.state.isError ? (
                        <div className="alert alert-danger" role="alert">
                          {this.state.errorMsg}
                        </div>
                      ) : this.state.spinner ? (
                        <div className="row">
                          <div className="col-12 d-flex justify-content-center">
                            <div
                              className="spinner-border text-danger"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                      ) : this.state.isValid ? (
                        <div className="alert alert-success" role="alert">
                          {this.state.validMsg}
                        </div>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-block text-white"
                          onClick={this.handleClick}
                          style={{ backgroundColor: "#CD3235" }}
                        >
                          Login
                        </button>
                      )}
                    </div>
                    <div className="col-12 mt-3 text-right">
                      <div className="form-check">
                        <label className="form-check-label login-subtext">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name=""
                            id=""
                            value="checkedValue"
                          />
                          Remember me?
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <div className="row">
                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                          <button
                            type="button"
                            className="btn btn-block text-white"
                            style={{
                              backgroundColor: "#36528C",
                            }}
                          >
                            Login with
                            <i
                              className="fab fa-facebook-square text-white ml-3"
                              aria-hidden="true"
                              style={{
                                verticalAlign: "middle",
                                fontSize: "25px",
                              }}
                            ></i>
                          </button>
                        </div>
                        <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                          <button
                            type="button"
                            className="btn btn-block google-btn"
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            Login with
                            <i
                              className="fab fa-google ml-3"
                              aria-hidden="true"
                              style={{
                                verticalAlign: "middle",
                                fontSize: "25px",
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <p className="login-subtext text-center">
                        Not Registered? <a href="/register">Click Here</a>
                      </p>
                    </div>
                    <div className="col-12 mt-2 text-center">
                      <p className="login-subtext">
                        <a href="/forgotPassword">Forgot Password?</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginState: state.appStore.login,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loginUser: (data) => dispatch(loginUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
