import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../Login/index";
import Register from "../Register/index";
function AuthRoutes(props) {
  let status = props.status;
  let setStatus = props.setStatus;
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => (
          <Login {...props} status={status} setStatus={setStatus} />
        )}
      />
      <Route
        path="/register"
        exact
        render={(props) => (
          <Register {...props} status={status} setStatus={setStatus} />
        )}
      />
    </Switch>
  );
}

export default AuthRoutes;
