import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_LIVE_POST,
  ACTION_GET_LIVE_POST_BY_ID,
} from "../../constants/action";
const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getLivePost = (from, to, language) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_LIVE_POST, data: {} };
  dispatch(actionData);
  await AppServices.getLivePost(from, to, language)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getLivePostById = (id) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_LIVE_POST_BY_ID,
    data: {},
  };
  dispatch(actionData);
  await AppServices.getLivePostById(id)
    .then(checkResponseStatus)
    .then((res) => {
      // console.log("res", res.data);
      actionData = {
        ...actionData,
        isLoading: false,
        isError: res.data.status !== 1 ? true : false,
        message: res.data.message,
        success: res.data.status === 1 ? true : false,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
