import React, { PureComponent, Fragment } from "react";
import "./index.css";
import politics from "../../../img/no-img.jpg";
import NoImg from "../../../img/no-img.jpg";

import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";

import { connect } from "react-redux";
import { getPostByCategory } from "../../../store/actions/posts-actions";
import { addCount } from "../../../store/actions/app-state-action";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../../constants/api";
import moment from "moment";
import { withRouter } from "react-router";
class CategoryNews extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      foreign: [],
      sports: [],
      politics: [],
      isSports: false,
      isForeign: false,
      isPolitics: false,
      adList: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.postCategoryState !== prevProps.postCategoryState) {
      if (
        this.props.postCategoryState["Foreign"].success &&
        this.props.postCategoryState["Sports"].success &&
        this.props.postCategoryState["Politics"].success
      ) {
        this.setState({
          ...this.state,
          foreign: this.props.postCategoryState["Foreign"].data,
          sports: this.props.postCategoryState["Sports"].data,
          politics: this.props.postCategoryState["Politics"].data,
          success: true,
          isForeign: true,
          isSports: true,
          isPolitics: true,
        });
      }
    }

    if (prevProps.adState !== this.props.adState) {
      if (this.props.adState.success && !this.props.adState.isLoading) {
        this.setState({
          ...this.state,
          adList: this.props.adState.data,
        });
      }
    }
  }

  componentDidMount() {
    if (
      !this.props.postCategoryState["Foreign"] ||
      !this.props.postCategoryState["Foreign"].success
    ) {
      this.props.getPostByCategory("Foreign", 1, 10); //foreign
    } else {
      this.setState({
        foreign: this.props.postCategoryState["Foreign"].data,
        isForeign: true,
      });
    }

    if (
      !this.props.postCategoryState["Sports"] ||
      !this.props.postCategoryState["Sports"].success
    ) {
      this.props.getPostByCategory("Sports", 1, 10); //sports
    } else {
      this.setState({
        sports: this.props.postCategoryState["Sports"].data,
        isSports: true,
      });
    }

    if (
      !this.props.postCategoryState["Politics"] ||
      !this.props.postCategoryState["Politics"].success
    ) {
      this.props.getPostByCategory("Politics", 1, 10); //politics
    } else {
      this.setState({
        politics: this.props.postCategoryState["Politics"].data,
        isPolitics: true,
      });
    }
  }

  handleRedirect = (id) => {
    window.top.location.href = `/article.php?slug=` + id;
    // this.props.history.push(`/article/` + id);
  };

  handleCategory = (name, id) => {
    window.top.location.href = `/category/` + name;
    // this.props.history.push(`/category/` + name);
  };

  handleCount = (id, count, url) => {
    var fromData = {
      id: id,
      count: parseInt(count),
    };
    this.props.addCount(fromData);
    window.top.location.href = url;
  };

  addDefaultSrc(e, item) {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  }

  render() {
    const items = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <Fragment>
        <div className="foreign-news-wrapper">
          <div className="row mx-0">
            <div className="col-12">
              {this.state.adList &&
                this.state.adList.length !== 0 &&
                this.state.adList.map((item, index) => {
                  if (item.Section === "Section 04") {
                    return (
                      <div className="ad mt-4 mb-4" key={index}>
                        <img
                          src={API_STORAGE_BASE_URL + item.Thumbnail.toString()}
                          onClick={(e) =>
                            this.handleCount(item.Id, item.Count, item.Url)
                          }
                          onError={this.addDefaultSrc}
                          style={{ cursor: "pointer" }}
                          alt="adv-img-4"
                        />
                      </div>
                    );
                  }
                })}
            </div>
            <div className="col-12 col-sm-4 pl-0 pr-0 pr-sm-3 my-2">
              <div className="card trending-news-card h-100">
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-6">
                      <label className="foreign-button">Foreign</label>
                    </div>
                    <div className="col-6 text-right my-auto">
                      <p
                        className="foreign-text-01"
                        onClick={(e) => this.handleCategory("Foreign", 10)}
                      >
                        View More
                      </p>
                    </div>
                  </div>
                  {this.state.isForeign ? (
                    this.state.foreign.length !== 0 ? (
                      <Fragment>
                        {this.state.foreign
                          .map((item, index) => {
                            var thumbnail = "";
                            if (item.Thumbnail) {
                              var getImagePath = item.Thumbnail.split("/");
                              var thumb = getImagePath[3].split(".");
                              thumbnail = thumb[0];
                            }
                            return (
                              <Fragment key={index}>
                                <div
                                  className="row mb-1 pt-2 news-row"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    this.handleRedirect(item.Slug)
                                  }
                                >
                                  <div className="col-8 pr-0">
                                    <h1 className="trending-news-text">
                                      {item.Title}
                                    </h1>
                                    <div className="row pl-3 pr-3 mb-3">
                                      <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                      <p className="trending-news-subtext my-auto">
                                        {moment(item.DateCreated)
                                          .local()
                                          .startOf("seconds")
                                          .fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="news-img-container placeholder-img">
                                      <img
                                        className="trending-img"
                                        src={
                                          thumbnail.length !== 0 && thumbnail
                                            ? API_STORAGE_BASE_THUMB_URL +
                                              thumbnail +
                                              ".jpeg"
                                            : item.Thumbnail.length !== 0 &&
                                              item.Thumbnail
                                            ? API_STORAGE_BASE_URL +
                                              item.Thumbnail
                                            : politics
                                        }
                                        onError={(e) => {
                                          this.addDefaultSrc(e, item.Thumbnail);
                                        }}
                                        alt={`${item.Title.substring(
                                          0,
                                          60
                                        )}...`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            );
                          })
                          .slice(0, 9)}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="row d-flex justify-content-center">
                          <p>There are no articles.</p>
                        </div>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      {items
                        .map((item) => {
                          return (
                            <div className="row mb-2" key={item}>
                              <div className="col-8 ">
                                <Skeleton variant="rect" height={40} />
                                <div className="row pl-3 pr-3 mb-3 mt-2">
                                  <p className="trending-news-subtext my-auto">
                                    <Skeleton
                                      variant="rect"
                                      height={14}
                                      width={76}
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="news-img-container">
                                  <Skeleton variant="rect" height={60} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                        .slice(0, 8)}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 pl-0 pr-0 pl-sm-3 pr-sm-3 my-2">
              <div className="card politics-card h-100">
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-6">
                      <label className="politics-button">Politics</label>
                    </div>
                    <div className="col-6 text-right my-auto">
                      <p
                        className="foreign-text-01"
                        onClick={(e) => this.handleCategory("Politics", 12)}
                      >
                        View More
                      </p>
                    </div>
                  </div>
                  <div className="row p-1 mx-0 d-flex justify-content-center">
                    {this.state.isPolitics ? (
                      this.state.politics.length !== 0 ? (
                        <Fragment>
                          {this.state.politics
                            .map((item, index) => {
                              var thumbnail = "";
                              if (item.Thumbnail) {
                                var getImagePath = item.Thumbnail.split("/");
                                var thumb = getImagePath[3].split(".");
                                thumbnail = thumb[0];
                              }
                              return (
                                <Fragment key={index}>
                                  <div
                                    className="col-6 mt-2 p-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      this.handleRedirect(item.Slug)
                                    }
                                  >
                                    <div className="politics-img-container">
                                      <img
                                        className="politics-img"
                                        src={
                                          thumbnail.length !== 0 && thumbnail
                                            ? API_STORAGE_BASE_THUMB_URL +
                                              thumbnail +
                                              ".jpeg"
                                            : item.Thumbnail.length !== 0 &&
                                              item.Thumbnail
                                            ? API_STORAGE_BASE_URL +
                                              item.Thumbnail
                                            : politics
                                        }
                                        onError={(e) => {
                                          this.addDefaultSrc(e, item.Thumbnail);
                                        }}
                                        alt={`${item.Title.substring(
                                          0,
                                          60
                                        )}...`}
                                      />
                                      <h1 className="politics-text">
                                        {item.Title.length > 40
                                          ? item.Title.slice(0, 40) + "..."
                                          : item.Title}
                                      </h1>
                                    </div>
                                  </div>
                                </Fragment>
                              );
                            })
                            .slice(0, 8)}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="row d-flex justify-content-center">
                            <p>There are no articles.</p>
                          </div>
                        </Fragment>
                      )
                    ) : (
                      <Fragment>
                        {items
                          .map((item) => {
                            return (
                              <div className="col-6" key={item}>
                                <p>
                                  <Skeleton variant="rect" height={123} />
                                </p>
                              </div>
                            );
                          })
                          .slice(0, 8)}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-4 pl-0 pl-sm-3 pr-0 my-2">
              {/* <div className="row">
                <div className="col-12">
                  {this.state.adList &&
                    this.state.adList.length !== 0 &&
                    this.state.adList.map((item, index) => {
                      if (item.Section === "Section 04") {
                        return (
                          <div className="politics-ad mt-5 mb-5" key={index}>
                            <img
                              src={API_STORAGE_BASE_URL + item.Thumbnail}
                              alt="advertisement"
                              onClick={(e) =>
                                this.handleCount(item.Id, item.Count, item.Url)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </div> */}
              <div className="card sports-card h-100">
                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-6">
                      <label className="sports-button">Sports</label>
                    </div>
                    <div className="col-6 text-right my-auto">
                      <p
                        className="foreign-text-01"
                        onClick={(e) => this.handleCategory("Sports", 11)}
                      >
                        View More
                      </p>
                    </div>
                  </div>
                  {this.state.isSports ? (
                    this.state.sports.length !== 0 ? (
                      <Fragment>
                        {this.state.sports
                          .map((item, index) => {
                            var thumbnail = "";
                            if (item.Thumbnail) {
                              var getImagePath = item.Thumbnail.split("/");
                              var thumb = getImagePath[3].split(".");
                              thumbnail = thumb[0];
                            }
                            return (
                              <Fragment key={index}>
                                <div
                                  className="row mb-1 pt-2 news-row"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    this.handleRedirect(item.Slug)
                                  }
                                >
                                  <div className="col-8 pr-0">
                                    <p className="trending-news-text">
                                      {item.Title}
                                    </p>
                                    <div className="row pl-3 pr-3 mb-3">
                                      <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                      <p className="trending-news-subtext my-auto">
                                        {moment(item.DateCreated)
                                          .local()
                                          .startOf("seconds")
                                          .fromNow()}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="news-img-container placeholder-img">
                                      <img
                                        className="trending-img"
                                        src={
                                          thumbnail.length !== 0 && thumbnail
                                            ? API_STORAGE_BASE_THUMB_URL +
                                              thumbnail +
                                              ".jpeg"
                                            : item.Thumbnail.length !== 0 &&
                                              item.Thumbnail
                                            ? API_STORAGE_BASE_URL +
                                              item.Thumbnail
                                            : politics
                                        }
                                        onError={(e) => {
                                          this.addDefaultSrc(e, item.Thumbnail);
                                        }}
                                        alt={`${item.Title.substring(
                                          0,
                                          60
                                        )}...`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Fragment>
                            );
                          })
                          .slice(0, 9)}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="row d-flex justify-content-center">
                          <p>There are no articles.</p>
                        </div>
                      </Fragment>
                    )
                  ) : (
                    <Fragment>
                      {items
                        .map((item) => {
                          return (
                            <div className="row mb-2" key={item}>
                              <div className="col-8 ">
                                <Skeleton variant="rect" height={40} />
                                <div className="row pl-3 pr-3 mb-3 mt-2">
                                  <p className="trending-news-subtext my-auto">
                                    <Skeleton
                                      variant="rect"
                                      height={14}
                                      width={76}
                                    />
                                  </p>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="news-img-container">
                                  <Skeleton variant="rect" height={60} />
                                </div>
                              </div>
                            </div>
                          );
                        })
                        .slice(0, 8)}
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.posts,
    postCategoryState: state.postStore.category,
    adState: state.appStore.ads,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostByCategory: (key, from, to) =>
    dispatch(getPostByCategory(key, from, to)),
  addCount: (data) => dispatch(addCount(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryNews)
);
