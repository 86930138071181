import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { getPostByTag } from "../../store/actions/posts-actions";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import NoImg from "../../img/no-img.jpg";
import moment from "moment";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../constants/api";

import "./index.css";
class TagsView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { tags: [], success: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getPostByTag(this.props.match.params.name);
  }

  componentDidUpdate(prevProps) {
    if (this.props.tagState !== prevProps.tagState) {
      if (this.props.tagState.success) {
        if (
          !this.props.tagState.isLoading &&
          this.props.tagState.status &&
          this.props.tagState.data.length <= 24
        ) {
          this.props.getPostByTag(this.props.match.params.name);
        }
        this.setState({
          ...this.state,
          tags: this.props.tagState.data,
          success: true,
        });
      }
    }
  }

  handleRedirect = (id) => {
    window.top.location.href = `/article.php?slug=` + id;
    // this.props.history.push(`/article/` + id);
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 && item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const items = [1, 2, 3, 4, 5, 6];
    return (
      <Fragment>
        <div className="tag-wrapper">
          <h3 style={{ fontWeight: "600" }}>
            Tag Search : <u>{this.props.match.params.name}</u>
          </h3>
          <div className="row">
            {this.state.success ? (
              this.state.tags.length === 0 ? (
                <div className="col-12 mt-4">
                  <h5>No Article was found with this Tag</h5>
                </div>
              ) : (
                this.state.tags.length !== 0 &&
                this.state.tags.map((item, index) => {
                  var thumbnail = "";
                  if (item.Thumbnail) {
                    var getImagePath = item.Thumbnail.split("/");
                    var thumb = getImagePath[3].split(".");
                    thumbnail = thumb[0];
                  }
                  return (
                    <Fragment key={index}>
                      <div className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3">
                        <div
                          className="card latest-news-card"
                          onClick={(e) => this.handleRedirect(item.Slug)}
                        >
                          <div className="latest-news-img-container">
                            <img
                              className="latest-news-img"
                              src={
                                thumbnail.length !== 0 && thumbnail
                                  ? API_STORAGE_BASE_THUMB_URL +
                                    thumbnail +
                                    ".jpeg"
                                  : item.Thumbnail.length !== 0 &&
                                    item.Thumbnail
                                  ? API_STORAGE_BASE_URL + item.Thumbnail
                                  : NoImg
                              }
                              onError={(e) => {
                                this.addImgErrorDefaultSrc(e, item.Thumbnail);
                              }}
                              alt={`${item.Title.substring(0, 60)}...`}
                            />
                          </div>
                          <div className="card-body p-3">
                            <button
                              type="button"
                              className="btn btn-sm latest-news-button"
                            >
                              {item.Category}
                            </button>
                            <div className="row pl-3 pr-3 latest-news-subtext">
                              <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                              <p>
                                {moment(item.DateCreated)
                                  .local()
                                  .startOf("seconds")
                                  .fromNow()}
                              </p>
                            </div>
                            <p className="ln-text-01">{item.Title}</p>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              )
            ) : (
              <Fragment>
                {items.map((item, index) => {
                  return (
                    <div
                      className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3"
                      key={index}
                    >
                      <div className="card latest-news-card">
                        <div className="latest-news-img-container">
                          <Skeleton variant="rect" height={189} />
                        </div>
                        <div className="card-body p-3">
                          <div className="row pl-3 pr-3 mb-2">
                            <Skeleton variant="rect" width={110} height={18} />
                          </div>
                          <Skeleton variant="rect" height={40} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagState: state.postStore.tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostByTag: (key) => dispatch(getPostByTag(key)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TagsView)
);
