import {
  ACTION_AUTH_LOGIN,
  ACTION_AUTH_REGISTER,
  ACTION_GET_TOKEN,
  ACTION_GET_ADS,
  ACTION_ADD_COUNT,
  ACTION_GET_ALL_INFO,
  ACTION_ADD_VIEW_COUNT,
  ACTION_FORGOT_PASSWORD,
  ACTION_RESET_PASSWORD,
  ACTION_SEND_OTP,
} from "../../constants/action";

const initialState = {
  login: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    status: 0,
    data: {},
  },
  register: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  refreshToken: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  ads: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  count: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  viewCount: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  info: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: [],
  },
  forgot: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  reset: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  code: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
};

export default function appStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_AUTH_LOGIN: {
      _state = {
        ...state,
        login: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
          status: action.status,
        },
      };
      break;
    }
    case ACTION_AUTH_REGISTER: {
      _state = {
        ...state,
        register: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    case ACTION_GET_TOKEN: {
      _state = {
        ...state,
        refreshToken: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_ADS: {
      _state = {
        ...state,
        ads: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_ADD_COUNT: {
      _state = {
        ...state,
        count: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_ADD_VIEW_COUNT: {
      _state = {
        ...state,
        viewCount: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_ALL_INFO: {
      _state = {
        ...state,
        info: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_FORGOT_PASSWORD: {
      _state = {
        ...state,
        forgot: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_RESET_PASSWORD: {
      _state = {
        ...state,
        reset: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_SEND_OTP: {
      _state = {
        ...state,
        code: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
