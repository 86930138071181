import {
  ACTION_GET_VIDEOS,
  ACTION_GET_LIVE_NEWS,
  ACTION_GET_VIDEO_BY_CATEGORY,
} from "../../constants/action";

const initialState = {
  video: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  live: {
    isLoading: false,
    isError: false,
    message: "",
    success: false,
    data: {},
  },
  category: [],
};
export default function videoStateReducer(state = initialState, action) {
  let _state;
  switch (action.type) {
    case ACTION_GET_VIDEOS: {
      _state = {
        ...state,
        video: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }

    case ACTION_GET_VIDEO_BY_CATEGORY: {
      _state = {
        ...state,
        category: {
          ...state.category,
          [action.key]: {
            isLoading: action.isLoading,
            isError: action.isError,
            message: action.message,
            success: action.success,
            data: state.category[action.key]
              ? state.category[action.key].data.concat(action.data)
              : action.data,
            status: action.status,
          },
        },
      };
      break;
    }

    case ACTION_GET_LIVE_NEWS: {
      _state = {
        ...state,
        live: {
          isLoading: action.isLoading,
          isError: action.isError,
          message: action.message,
          success: action.success,
          data: action.data,
        },
      };
      break;
    }
    default: {
      _state = state;
      break;
    }
  }
  return _state;
}
