import AppServices from "../../services/app-services";
import { checkResponseStatus } from "../../utils/http-utils";
import {
  ACTION_GET_VIDEOS,
  ACTION_GET_LIVE_NEWS,
  ACTION_GET_VIDEO_BY_CATEGORY,
} from "../../constants/action";

const commonData = {
  isLoading: true,
  isError: false,
  message: "",
  success: false,
};

export const getAllVideo = (from, to) => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_VIDEOS, data: {} };
  dispatch(actionData);
  await AppServices.getAllVideo(from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getLiveNews = () => async (dispatch) => {
  let actionData = { ...commonData, type: ACTION_GET_LIVE_NEWS, data: {} };
  dispatch(actionData);
  await AppServices.getLiveNews()
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};

export const getVideoByCategory = (data, id, from, to) => async (dispatch) => {
  let actionData = {
    ...commonData,
    type: ACTION_GET_VIDEO_BY_CATEGORY,
    data: [],
    key: id,
    status: false,
  };
  dispatch(actionData);
  await AppServices.getVideoByCategory(data, from, to)
    .then(checkResponseStatus)
    .then((res) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: false,
        message: res.data.message,
        success: true,
        data: res.data.data,
        key: id,
        status: res.data.data && res.data.data.length !== 0 ? true : false,
      };
    })
    .catch((err) => {
      actionData = {
        ...actionData,
        isLoading: false,
        isError: true,
        message: err.message,
        success: false,
      };
    });
  dispatch(actionData);
};
