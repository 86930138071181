export const ACTION_AUTH_LOGIN = "ACTION_AUTH_LOGIN";
export const ACTION_AUTH_REGISTER = "ACTION_AUTH_REGISTER";
export const ACTION_GET_TOKEN = "ACTION_GET_TOKEN";
export const ACTION_GET_ADS = "ACTION_GET_ADS";
export const ACTION_ADD_COUNT = "ACTION_ADD_COUNT";
export const ACTION_ADD_VIEW_COUNT = "ACTION_ADD_VIEW_COUNT";
export const ACTION_FORGOT_PASSWORD = "ACTION_FORGOT_PASSWORD";
export const ACTION_RESET_PASSWORD = "ACTION_RESET_PASSWORD";
export const ACTION_SEND_OTP = "ACTION_SEND_OTP";

export const ACTION_GET_ALL_POSTS = "ACTION_GET_ALL_POSTS";
export const ACTION_GET_POST_BY_ID = "ACTION_GET_POST_BY_ID";
export const ACTION_GET_POST_BY_CATEGORY = "ACTION_GET_POST_BY_CATEGORY";
export const ACTION_GET_POST_SEARCH = "ACTION_GET_POST_SEARCH";
export const ACTION_GET_POST_BY_SEARCH = "ACTION_GET_POST_BY_SEARCH";
export const ACTION_GET_FACTCHECK_ALL = "ACTION_GET_FACTCHECK_ALL";
export const ACTION_GET_POST_BY_TAG = "ACTION_GET_POST_BY_TAG";
export const ACTION_GET_TRENDING_POSTS = "ACTION_GET_TRENDING_POSTS";

export const ACTION_GET_VIDEOS = "ACTION_GET_VIDEOS";

export const ACTION_GET_LIVE_NEWS = "ACTION_GET_LIVE_NEWS";
export const ACTION_GET_VIDEO_BY_CATEGORY = "ACTION_GET_VIDEO_BY_CATEGORY";

export const ACTION_GET_LIVE_POST = "ACTION_GET_LIVE_POST";
export const ACTION_GET_LIVE_POST_BY_ID = "ACTION_GET_LIVE_POST_BY_ID";

export const ACTION_GET_ALL_INFO = "ACTION_GET_ALL_INFO";
