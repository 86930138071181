import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { getPostByCategory } from "../../store/actions/posts-actions";
import { withRouter } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import NoImg from "../../img/no-img.jpg";
import moment from "moment";
import {
  API_STORAGE_BASE_URL,
  API_STORAGE_BASE_THUMB_URL,
} from "../../constants/api";
import "./index.css";
class CategoryView extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      category: [],
      success: false,
      isLoading: false,
      categoryList: [
        "local",
        "foreign",
        "sports",
        "politics",
        "business",
        "opinion",
        "tech",
        "climate",
      ],
    };
  }

  componentDidMount() {
    document.getElementById("collapseExample").classList.remove("show");
    document.addEventListener("scroll", this.trackScrolling);
    window.scrollTo(0, 0);
    var isExist = this.state.categoryList.some(
      (item) => item === this.props.match.params.name
    );

    if (!isExist) {
      window.top.location.href = `/not-found`;
    }
    if (!this.props.postCategoryState[this.props.match.params.name]) {
      this.props.getPostByCategory(this.props.match.params.name, 1, 12);
    } else if (
      this.props.postCategoryState[this.props.match.params.name].success
    ) {
      this.setState({
        ...this.state,
        category:
          this.props.postCategoryState[this.props.match.params.name].data
            .length !== 0 &&
          this.props.postCategoryState[this.props.match.params.name].data[0]
            ? this.props.postCategoryState[this.props.match.params.name].data
            : [],
        success: true,
        isLoading: false,
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  isBottom(el) {
    if (el) {
      return el.getBoundingClientRect().bottom <= window.innerHeight;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.name !== this.props.match.params.name) {
      this.setState({
        ...this.state,
        success: false,
      });
      window.scrollTo(0, 0);
      if (
        this.props.postCategoryState[this.props.match.params.name] &&
        this.props.postCategoryState[this.props.match.params.name].status &&
        this.props.postCategoryState[this.props.match.params.name].success &&
        !this.props.postCategoryState[this.props.match.params.name].isLoading
      ) {
        this.setState({
          ...this.state,
          category: this.props.postCategoryState[this.props.match.params.name]
            .data[0]
            ? this.props.postCategoryState[this.props.match.params.name].data
            : [],
          success: true,
          isLoading: false,
        });
      } else {
        this.props.getPostByCategory(this.props.match.params.name, 1, 12);
      }
    }
    if (this.props.postCategoryState !== prevProps.postCategoryState) {
      if (this.props.postCategoryState[this.props.match.params.name].success) {
        this.setState({
          ...this.state,
          category: this.props.postCategoryState[this.props.match.params.name]
            .data[0]
            ? this.props.postCategoryState[this.props.match.params.name].data
            : [],
          success: true,
          isLoading: false,
        });
      }
    }
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("category");
    if (this.isBottom(wrappedElement)) {
      if (
        this.props.postCategoryState[this.props.match.params.name].status &&
        this.props.postCategoryState[this.props.match.params.name].data
          .length === 12
      ) {
        this.setState({ ...this.state, isLoading: true });
        this.props.getPostByCategory(
          this.props.match.params.name,
          this.props.postCategoryState[this.props.match.params.name].data
            .length + 1,
          12
        );
      }
    }
  };

  handleRedirect = (id) => {
    window.top.location.href = `/article.php?slug=` + id;
    // this.props.history.push(`/article/` + id);
  };

  addDefaultSrc(e) {
    e.target.src = NoImg;
  }

  addImgErrorDefaultSrc = (e, item) => {
    e.target.src =
      item.length !== 0 || item ? API_STORAGE_BASE_URL + item : NoImg;
  };

  render() {
    const items = [1, 2, 3, 4, 5, 6];
    return (
      <Fragment>
        <div className="category-wrapper" id="category">
          <h3
            style={{
              fontWeight: "600",
              textDecoration: "underline",
              textTransform: "capitalize",
            }}
          >
            {this.props.match.params.name === "climate"
              ? "Climate change"
              : this.props.match.params.name}
          </h3>
          <div className="row">
            {this.state.success ? (
              this.state.category.length === 0 ? (
                <div className="col-12 mt-4">
                  <h5>No article was found under this category.</h5>
                </div>
              ) : (
                this.state.category
                  .filter((v, i, a) => a.findIndex((t) => t.Id === v.Id) === i)
                  .map((item, index) => {
                    var thumbnail = "";
                    if (item.Thumbnail) {
                      var getImagePath = item.Thumbnail.split("/");
                      var thumb = getImagePath[3].split(".");
                      thumbnail = thumb[0];
                    }
                    return (
                      <Fragment key={index}>
                        <div className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3">
                          <a
                            href={`/article.php?slug=` + item.Slug}
                            className="card latest-news-card news-link"
                            style={{ cursor: "pointer" }}
                            // onClick={(e) => this.handleRedirect(item.Slug)}
                          >
                            <div className="latest-news-img-container">
                              <img
                                className="latest-news-img"
                                src={
                                  thumbnail.length !== 0 && thumbnail
                                    ? API_STORAGE_BASE_THUMB_URL +
                                      thumbnail +
                                      ".jpeg"
                                    : item.Thumbnail.length !== 0 &&
                                      item.Thumbnail
                                    ? API_STORAGE_BASE_URL + item.Thumbnail
                                    : NoImg
                                }
                                onError={(e) => {
                                  this.addDefaultSrc(e);
                                }}
                                alt={`${item.Title.substring(0, 60)}...`}
                              />
                            </div>
                            <div className="card-body p-3">
                              <button
                                type="button"
                                className="btn btn-sm latest-news-button"
                              >
                                {item.Category}
                              </button>
                              <div className="row pl-3 pr-3 latest-news-subtext">
                                <QueryBuilderIcon className="mr-1 mr-sm-2 clock-icon" />
                                <p>
                                  {moment(item.DateCreated)
                                    .local()
                                    .startOf("seconds")
                                    .fromNow()}
                                </p>
                              </div>
                              <h1 className="ln-text-01">
                                {item.Title.length > 60
                                  ? `${item.Title.substring(0, 60)}...`
                                  : item.Title}
                              </h1>
                            </div>
                          </a>
                        </div>
                      </Fragment>
                    );
                  })
              )
            ) : (
              <Fragment>
                {items.map((item, index) => {
                  return (
                    <div
                      className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3"
                      key={index}
                    >
                      <div className="card latest-news-card">
                        <div className="latest-news-img-container">
                          <Skeleton variant="rect" height={189} />
                        </div>
                        <div className="card-body p-3">
                          <div className="row pl-3 pr-3 mb-2">
                            <Skeleton variant="rect" width={110} height={18} />
                          </div>
                          <Skeleton variant="rect" height={40} />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            )}
            {this.state.isLoading ? (
              <Fragment>
                <div className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3">
                  <div className="card latest-news-card">
                    <div className="latest-news-img-container">
                      <Skeleton variant="rect" height={189} />
                    </div>
                    <div className="card-body p-3">
                      <div className="row pl-3 pr-3 mb-2">
                        <Skeleton variant="rect" width={110} height={18} />
                      </div>
                      <Skeleton variant="rect" height={40} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3">
                  <div className="card latest-news-card">
                    <div className="latest-news-img-container">
                      <Skeleton variant="rect" height={189} />
                    </div>
                    <div className="card-body p-3">
                      <div className="row pl-3 pr-3 mb-2">
                        <Skeleton variant="rect" width={110} height={18} />
                      </div>
                      <Skeleton variant="rect" height={40} />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4 pl-3 pl-sm-0 mt-3">
                  <div className="card latest-news-card">
                    <div className="latest-news-img-container">
                      <Skeleton variant="rect" height={189} />
                    </div>
                    <div className="card-body p-3">
                      <div className="row pl-3 pr-3 mb-2">
                        <Skeleton variant="rect" width={110} height={18} />
                      </div>
                      <Skeleton variant="rect" height={40} />
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    postState: state.postStore.posts,
    postCategoryState: state.postStore.category,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPostByCategory: (key, from, to) =>
    dispatch(getPostByCategory(key, from, to)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategoryView)
);
